export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 80 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'

        //Close menu when opening eco-bar
        $('[data-toggle="#eco-bar"]').on('click', () => {
            $('body').removeClass('menu-open').removeClass('submenu-open')
        })

        //Open/Close menu
        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open').removeClass('submenu-open')

            $('.fullscreen-menu__item').removeClass('active')
            $("[data-element='wpiris-menu-more']").parent().removeClass('active')
            $('.fullscreen-menu__column--3 img').removeClass('new')

            $('#eco-bar').addClass('eco-bar--hidden')
        })
    }
}
