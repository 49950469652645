/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import hoverImageChangerComponent from './components/hoverImageChanger.component'
import MenuComponent from './components/menu.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    // Components
    new NavbarComponent()
    new MenuComponent()
    new hoverImageChangerComponent()

    // Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
            ).then(({default: HomeService}) => {
            new HomeService()
        })
    }

    // Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
            ).then(({default: LayoutService}) => {
            new LayoutService()
        })
    }

    // Smooth scroll activation
    for (const anchor of document.querySelectorAll(
        'a[href^="#"]:not([data-hc-modal])'
    )) {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            const href = this.getAttribute("href");

            if (href && href !== "#" && document.querySelector(href)) {
                const targetElement = document.querySelector(href);
                targetElement.scrollIntoView({
                    behavior: "smooth",
                });
            }
        });
    }


    //Managing scroll reveal for sections
    const sectionViewObserver = new IntersectionObserver(
        (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('viewed')
                    entry.target.classList.add('in-view')
                } else {
                    entry.target.classList.remove('in-view')
                }
            }
        },
        {rootMargin: '-15%'},
    )
    for (const section of document.querySelectorAll('section')) {
        sectionViewObserver.observe(section)
    }

    //Managing scroll reveal for data-appear-order elements & others (the only difference is the rootMargin)
    const dataAppearOrderViewObserver = new IntersectionObserver(
        (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('viewed')
                    entry.target.classList.add('in-view')
                } else {
                    entry.target.classList.remove('in-view')
                }
            }
        },
        {rootMargin: '0px'},
    )

    const homeElement = document.querySelector('.home');
    if (homeElement) {
        dataAppearOrderViewObserver.observe(homeElement);
    }

    for (const section of document.querySelectorAll('[data-appear-order]')) {
        dataAppearOrderViewObserver.observe(section)
    }

    $('[data-appear-order]').each(function () {
        $(this).css({transitionDelay: `${$(this).data('appear-order') * 0.75}s`})
    })
})
