export default class hoverImageChangerComponent {
    constructor() {
        hoverImageChangerComponent.onHover()
    }

    static onHover() {
        // previousTrigger is used to prevent retriggering the same element
        const previousTrigger = []
        const previousTimeout = []
        const isGenerating = []

        $('[data-image-url]').on('mouseenter', function () {
            const imageUrl = $(this).data('image-url')
            const target = $(this).data('image-trigger')

            // If we're on a mobile device or hovering the already hovered target, cancel
            if (window.matchMedia('(max-width: 1000px)').matches) return
            if (previousTrigger[target] === this) return

            previousTrigger[target] = this
            $(`[data-image-trigger=${target}]`).removeClass('active')
            $(this).addClass('active')

            clearTimeout(previousTimeout[target])
            //console.log('STEP 1: cleaned previous timeout');

            if (imageUrl.length) {
                const picturesContainer = `[data-image-element=${target}]`
                const pictures = `[data-image-element=${target}] img`

                if (!isGenerating[target]) {
                    //console.log('STEP 2: removing .new from: ', $(pictures));
                    $(pictures).removeClass('new')
                    isGenerating[target] = true
                }
                $(picturesContainer).append(
                    `<img src=${imageUrl} alt=${target} class='loading new'>`,
                )
                //console.log('STEP 3: created a new img element with a .new and a .loading class');

                $(pictures)
                    .filter('.loading')
                    .bind('load', function () {
                        $(this).removeClass('loading')
                        //console.log('STEP 4: removed the .loading class from the .new img');

                        previousTimeout[target] = setTimeout(() => {
                            //console.log('STEP 5: will be removed: ', $(pictures).not(currentImg));
                            $(pictures).not(this).remove()
                            isGenerating[target] = false
                        }, 800)
                    })
            }
        })
    }
}
