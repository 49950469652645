export default class MenuComponent {
    constructor() {
        MenuComponent.title()
        MenuComponent.arrows()
    }

    static title() {
        const title = '.fullscreen-menu__item' // Can't use a data-trigger as it's defined conditionally in the template
        const submenuTarget = 'wpiris-menu-more'
        let currentSubmenu
        let previousSubmenu

        //Open/Close submenu
        $(title).on('mouseenter', function () {
            // If we're on a mobile device, cancel
            if (window.matchMedia('(max-width: 1000px)').matches) return

            $(`[data-element=${submenuTarget}]`).parent().removeClass('active')
            $(title).removeClass('active')
            $(this).addClass('active')

            // Checking previous submenu opening
            if ($(this).data('submenu-id')) {
                if (previousSubmenu === $(this).data('submenu-id')) {
                    $("[data-image-element='menu-image'] img").removeClass('old')
                }
                previousSubmenu = $(this).data('submenu-id')
            }
            currentSubmenu = $(this).data('submenu-id')

            // Checking whether the current title has a submenu
            if ($(this).is(`[data-trigger=${submenuTarget}]`)) {
                $('body').addClass('submenu-open')
                $(`[data-element=${submenuTarget}]`)
                    .filter(`[data-submenu-id=${currentSubmenu}]`)
                    .parent()
                    .addClass('active')
            } else {
                $('body').removeClass('submenu-open')
                $("[data-image-element='menu-image'] img").addClass('old')
            }
        })
    }

    static arrows() {
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenuTarget = 'wpiris-menu-more'

        $(more).on('click', function (e) {
            // If we're on a desktop, cancel
            if (window.matchMedia('(min-width: 1000px)').matches) return

            e.preventDefault()

            const title = $(this).closest('.fullscreen-menu__item') // Can't use a data-trigger as it's defined conditionally in the template
            const currentSubmenu = $(title).data('submenu-id')

            $('body').addClass('submenu-open')
            $(`[data-element=${submenuTarget}]`)
                .filter(`[data-submenu-id=${currentSubmenu}]`)
                .parent()
                .addClass('active')
        })

        $(prev).on('click', (e) => {
            // If we're on a desktop, cancel
            if (window.matchMedia('(min-width: 1000px)').matches) return

            e.preventDefault()

            $(`[data-element=${submenuTarget}]`).parent().removeClass('active')
            $('body').removeClass('submenu-open')
        })
    }
}
